<template>
  <v-row>
    <v-col>
      <h5>Url Restriction Type</h5>
      <v-container fluid>
        <p class="text-caption">{{ selected_restriction }}</p>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL contains the specified URL"
          value="CONTAINS"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL exactly matches the specified URL."
          value="EQUALS"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL starts with the specified URL."
          value="STARTS_WITH"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL ends with the specified URL."
          value="ENDS_WITH"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL  does not equal the specified URL."
          value="DOES_NOT_EQUAL"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL  does not contain the specified URL."
          value="DOES_NOT_CONTAIN"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL does not start with the specified URL."
          value="DOES_NOT_START_WITH"
          hide-details="auto"
        ></v-checkbox>
        <v-checkbox
          v-model="selected_restriction"
          label="The tag URL  does not end with the specified URL."
          value="DOES_NOT_END_WITH"
          hide-details="auto"
        ></v-checkbox>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    selected_restriction: null,
  }),
  watch: {
    selected_restriction() {
      this.$emit("setRestrictionType", this.selected_restriction);
    },
    single_user_list() {
      this.selected_restriction = this.single_user_list.urlRestriction
        ? this.single_user_list.urlRestriction.restrictionType
        : null;
    },
  },
  props: {
    single_user_list: {
      type: Object,
    },
  },
};
</script>
