<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex align-center justify-space-between">
        <v-breadcrumbs :items="items"></v-breadcrumbs>
      </div>
      <v-divider></v-divider>
    </v-col>
    <v-col>
      <general-card class="pa-5">
        <user-list
          @setDisplayName="getName($event)"
          @setDescription="getDescription($event)"
          @setDuration="getDuration($event)"
          @setURL="getUrl($event)"
          @setStatus="getStatus($event)"
          :single_user_list="single_user_list"
          :isValid="isValid"
        />
      </general-card>
    </v-col>
    <v-col>
      <general-card class="pa-5">
        <url-restriction-type
          @setRestrictionType="getRestrictionType($event)"
          :single_user_list="single_user_list"
        />
      </general-card>
      <div class="d-flex align-center justify-space-between mt-5">
        <v-spacer></v-spacer>
        <v-btn
          v-if="$route.name == 'create-user-list'"
          depressed
          color="primary"
          @click="submit"
        >
          Submit
        </v-btn>
        <v-btn
          v-if="$route.name == 'update-user-list'"
          depressed
          color="primary"
          @click="update"
        >
          Update
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import UserList from "../../../components/Programmatic/UserList.vue";
import UrlRestrictionType from "../../../components/Programmatic/UrlRestrictionType.vue";
import GeneralCard from "../../../components/Layouts/Cards/GeneralCard.vue";

export default {
  data: () => ({
    displayName: null,
    description: null,
    duration: null,
    url: null,
    restrictiontype: null,
    status: null,
    items: [
      {
        text: "User Lists",
        disabled: false,
        href: "#/tradedesk/user-lists",
      },
      {
        text: "Add/ Edit User",
        disabled: true,
        href: "#",
      },
    ],
    error: null,
    isValid: true,
  }),
  components: {
    UserList,
    GeneralCard,
    UrlRestrictionType,
  },
  computed: {
    single_user_list() {
      return this.$store.state.dsp.single_user_list;
    },
  },
  async mounted() {
    if (this.$route.name == "update-user-list") {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/getSingleUserList", this.$route.params.id);
        this.$store.commit("makingApiRequest", false);
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    }
  },
  methods: {
    getName(displayName) {
      this.displayName = displayName;
    },
    getDescription(description) {
      this.description = description;
    },
    getDuration(duration) {
      this.duration = duration;
    },
    getUrl(url) {
      this.url = url;
    },
    getRestrictionType(restrictiontype) {
      this.restrictiontype = restrictiontype;
    },
    getStatus(status) {
      this.status = status;
    },

    async submit() {
      this.isValid = !this.isValid;

      if (!this.displayName || !this.duration) return;

      const body = {
        displayName: this.displayName + Math.random().toString(16).slice(2),
        description: this.description,
        status: this.status,
        urlRestriction: {
          url: this.url,
          restrictionType: this.restrictiontype,
        },
        membershipDurationDays: this.duration,
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/createUserList", body);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "UserList created successfully!",
          group: "success",
        });

        this.$router.push("/tradedesk/user-lists");
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },

    async update() {
      this.isValid = !this.isValid;

      if (!this.displayName || !this.duration) return;

      let combined_data = {
        user_list_id: this.$route.params.id,
        body: {
          displayName: this.displayName,
          description: this.description,
          status: this.status,
          urlRestriction: {
            url: this.url,
            restrictionType: this.restrictiontype,
          },
          membershipDurationDays: this.duration,
        },
      };

      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/updateUserList", combined_data);
        this.$store.commit("makingApiRequest", false);

        this.$notify({
          title: "UserList updated successfully!",
          group: "success",
        });

        this.$router.push("/tradedesk/user-lists");
      } catch (error) {
        this.$notify({
          title: error,
          group: "errors",
        });
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
