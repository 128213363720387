<template>
  <v-form ref="form" lazy-validation class="campaign-form" v-model="valid">
    <v-row no-gutters>
      <v-col cols="12" class="mb-3">
        <h5 class="mb-2">User List Name<span class="error--text">*</span></h5>
        <v-text-field
          v-model="displayName"
          required
          single-line
          label="User list name"
          outlined
          dense
          hide-details="auto"
          :rules="[(v) => !!v || 'User list name is required!']"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="mb-3">
        <h5 class="mb-2">Description</h5>
        <v-text-field
          v-model="description"
          single-line
          label="User list description"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="mb-3">
        <h5 class="mb-2">Membership Duration Days<span class="error--text">*</span></h5>
        <v-text-field
          type="number"
          v-model="duration"
          required
          single-line
          label="Membership duration days"
          outlined
          hide-details="auto"
          dense
          :rules="[(v) => !!v || 'Membership duration days is required!']"
          >No Negative Values</v-text-field
        >
      </v-col>

      <v-col cols="12" class="mb-3">
        <h5 class="mb-2">Url Restriction</h5>
        <v-text-field
          v-model="url"
          single-line
          label="Url Restrictions"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    displayName: null,
    description: null,
    duration: null,
    url: null,
    statuses: ["OPEN", "CLOSE"],
    selected_status: null,
    valid: true,
  }),
  props: {
    single_user_list: {
      type: Object,
    },
    isValid: {
      type: Boolean,
    },
  },
  watch: {
    isValid() {
      this.$refs.form.validate();
    },
    displayName() {
      this.$emit("setDisplayName", this.displayName);
    },
    description() {
      this.$emit("setDescription", this.description);
    },
    duration() {
      this.$emit("setDuration", this.duration);
    },
    url() {
      this.$emit("setURL", this.url);
    },
    selected_status() {
      this.$emit("setStatus", this.selected_status);
    },
    single_user_list() {
      if (this.single_user_list) {
        this.displayName = this.single_user_list.displayName
          ? this.single_user_list.displayName
          : null;
        this.url = this.single_user_list.urlRestriction
          ? this.single_user_list.urlRestriction.url
          : null;
        this.description = this.single_user_list.description
          ? this.single_user_list.description
          : null;
        this.selected_status = this.single_user_list.status;
        this.duration = this.single_user_list.membershipDurationDays.trim();
      }
    },
  },
};
</script>
